import useMatomo from "hooks/useMatomo";
import i18n from "i18n";
import React from "react";
import { AppProps } from "next/app";
import "tailwindcss/tailwind.css";
import Head from "next/head";

function App({ Component, pageProps }: AppProps) {
  useMatomo({
    matomoUrl: " https://analytics.realify.net",
    matomoSiteId: 3,
  });

  return (
    <>
      <Head>
        <title>Bjarn Bronsveld - Full Stack Developer</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />

        <meta
          name="description"
          content="Hi, I am Bjarn. A full stack web & mobile developer based in The Netherlands. I occasionally write about cutting edge technologies, tips and other interesting writes."
          key="description"
        />
        <meta name="twitter:card" content="summary_large_image" key="twitter_card" />
        <meta name="twitter:creator" content="@BjarnBronsveld" key="twitter_creator" />
        <meta name="twitter:site" content="@BjarnBronsveld" key="twitter_site" />
        <meta name="twitter:title" content="Bjarn Bronsveld - Full Stack Developer" key="twitter_title" />
        <meta
          name="twitter:description"
          content="Hi, I am Bjarn. A full stack web & mobile developer based in The Netherlands. I occasionally write about cutting edge technologies, tips and other interesting writes."
          key="twitter_description"
        />
        <meta
          name="twitter:image"
          content={`https://${process.env.VERCEL_URL || process.env.BASE_URL}/images/og_image.png`}
          key="twitter_image"
        />

        <meta property="og:type" content="website" key="og_type" />
        <meta
          property="og:image"
          content={`https://${process.env.VERCEL_URL || process.env.BASE_URL}/images/og_image.png`}
          key="og_image"
        />
        <meta property="og:site_name" content="Bjarn Bronsveld - Full Stack Developer" key="og_site_name" />
        <meta property="og:title" content="Bjarn Bronsveld - Full Stack Developer" key="og_title" />
        <meta
          property="og:description"
          content="Hi, I am Bjarn. A full stack web & mobile developer based in The Netherlands. I write about cutting edge technologies, occassional tips and other interesting writes."
          key="og_description"
        />
      </Head>
      <Component {...pageProps} />
    </>
  );
}

export default App;
