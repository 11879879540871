import { useRouter } from "next/router";
import { useEffect } from "react";

declare global {
  interface Window {
    _paq: any;
  }
}

type MatomoOptions = {
  matomoUrl?: string;
  matomoSiteId?: number;
};

function useMatomo({ matomoUrl, matomoSiteId }: MatomoOptions = {}) {
  const router = useRouter();

  useEffect(() => {
    if (!matomoUrl || !matomoSiteId) {
      return;
    }

    const scriptElement = document.createElement("script");
    const refElement = document.getElementsByTagName("script")[0];

    if (!refElement?.parentNode) {
      return;
    }

    const _paq = (window._paq = window._paq || []);

    let previousPath = "";

    _paq.push(["setDomains", ["*.bjarn.dev"]]);
    _paq.push(["disableCookies"]);
    _paq.push(["trackPageView"]);
    _paq.push(["enableLinkTracking"]);
    _paq.push(["setTrackerUrl", `${matomoUrl}/matomo.php`]);
    _paq.push(["setSiteId", `${matomoSiteId}`]);

    scriptElement.type = "text/javascript";
    scriptElement.async = true;
    scriptElement.defer = true;
    scriptElement.src = `${matomoUrl}/matomo.js`;
    refElement.parentNode.insertBefore(scriptElement, refElement);

    previousPath = window.location.pathname;

    const handleRouteChangeComplete = (path: string) => {
      const [pathname] = path.split("?");

      setTimeout(() => {
        if (previousPath) {
          _paq.push(["setReferrerUrl", `${previousPath}`]);
        }

        _paq.push(["setCustomUrl", pathname]);
        _paq.push(["setDocumentTitle", document.title]);
        _paq.push(["trackPageView"]);

        previousPath = pathname;
      }, 0);
    };

    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [matomoSiteId, matomoUrl, router.events]);
}

export default useMatomo;
